<template>
  <page-layout :show-back-button="false">
    <span slot="header-name">{{ $tf('users') }}</span>
    <el-button
      slot="header-actions"
      name="create-btn"
      class="header-button"
      circle
      icon="el-icon-plus"
      size="small"
      :disabled="$hasNoPermission('ffsecurity.add_user')"
      @click="$router.push({ path: '/users/create/' })"
    />
    <template v-slot:header-content-before-actions>
      <div class="users__pagination_wrapper">
        <table-navigation :state="state"></table-navigation>
      </div>
    </template>
    <table-generator
      slot="content"
      class="table-medium"
      :state="state"
      :schema="schema"
      :prop-to-field-map="propToFieldMap"
      @rowClick="rowClickHandler"
    ></table-generator>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/page/layout';
import { TableComponentNames } from '@/components/tables/components/names';
import TableGenerator from '@/components/tables/generator';
import TableNavigation from '@/components/tables/navigation';

export default {
  name: 'page-users',
  components: {
    TableNavigation,
    TableGenerator,
    PageLayout
  },
  data() {
    return {
      propToFieldMap: {
        login: 'username',
        active: 'is_active',
        groups: 'primary_group',
        real_name: 'first_name'
      }
    };
  },
  computed: {
    filter() {
      return this.$store.state.users.filter.current;
    },
    state() {
      return this.$store.state.users;
    },
    schema() {
      return [
        { label_i18n: 'id', prop: 'id', width: 80, sortable: 'custom' },
        { label_i18n: 'login', prop: 'login', component: TableComponentNames.UserProfileLink, sortable: 'custom' },
        { label_i18n: 'name', prop: 'real_name', sortable: 'custom' },
        { label_i18n: 'primary | role', prop: 'primary_group', formatter: this.getUserGroup, sortable: 'custom' },
        {
          label_i18n: 'active',
          prop: 'active',
          component: TableComponentNames.ActiveComponent,
          changeHandler: this.activeChangeHandler,
          width: 120,
          align: 'center',
          sortable: 'custom'
        }
      ];
    }
  },
  mounted() {
    this.$store.dispatch(this.$store.state.groups.Action.Get).then((v) => {
      this.$store.dispatch(this.state.Action.Get);
    });
  },
  methods: {
    rowClickHandler({ item }) {
      this.$router.push({ path: this.getItemLink(item) });
    },
    getItemLink(item) {
      return '/users/' + encodeURIComponent(item.id) + '/';
    },
    getUserGroup(item) {
      let value = item.primary_group;
      let group = this.$store.state.groups.items.find((v) => v.id === value);
      return group ? group.name : value;
    },
    activeChangeHandler(item, v) {
      return this.$store.dispatch(this.state.Action.Update, { id: item.id, active: v }).catch((e) => {
        item.active = !v;
        console.warn('[users]: ' + e);
      });
    }
  }
};
</script>

<style>
.users__pagination_wrapper {
  width: 48rem;
  text-align: right;
}
</style>
